import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { apis } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";

@Component({
  selector: "app-master-card-login",
  templateUrl: "./master-card-login.component.html",
  styleUrls: ["./master-card-login.component.scss"],
})
export class MasterCardLoginComponent implements OnInit {
  selectedCC: any = 95;
  user_details_disable: any = false;
  login: FormGroup;
  showCountryCode: any;

  constructor(
    public dialogRef: MatDialogRef<MasterCardLoginComponent>,
    private fb: FormBuilder,
    private crudService: CrudService,
    private ngxSpinner: NgxSpinnerService,
    public dialog: MatDialog,
    public toast: MatSnackBar
  ) {}

  ngOnInit() {
    this.createForm();
    // this.getCountryCode();
  }

  createForm() {
    this.login = this.fb.group({
      // mobile: [
      //   "",
      //   [
      //     Validators.required,
      //     Validators.minLength(10),
      //     Validators.maxLength(10),
      //     Validators.pattern("[0-9]+"),
      //   ],
      // ],
      // email: [
      //   "",
      //   Validators.compose([
      //     Validators.required,
      //     Validators.email,
      //     Validators.pattern(/\S+@\S+\.\S+/),
      //   ]),
      // ],
      // country_code: ["91"],
      bank: ["", Validators.required],
      customer_id: ["", Validators.required],
      usage: [
        "",
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
    });
  }

  // getCountryCode() {
  //   this.crudService.get(apis.COUNTRY_CODES).subscribe((data: any) => {
  //     this.showCountryCode = data.codes;
  //   });
  // }

  // select country code
  // selectedCountryCode(arg) {
  //   this.login.controls["country_code"].setValue(arg);
  // }

  // DropDown Value set function
  handleDropdownEvent(formControllName, value) {
    switch (formControllName) {
      case "bank":
        this.login.controls["bank"].setValue(value);

        break;
    }
  }

  masterCardLogin() {
    this.ngxSpinner.show();
    const reqBody = {
      // mobile: this.login.get("mobile").value,
      // country_code: this.login.get("country_code").value,
      // email: this.login.get("email").value,
      bank_name: this.login.get("bank").value,
      customerID: this.login.get("customer_id").value.toUpperCase(),
      no_of_usage: Number(this.login.get("usage").value),
    };
    this.crudService
      .postLogin(apis.MASTER_CARD_LOGIN, reqBody)
      .subscribe((res: any) => {
        if (res.redirect_url) {
          window.open(res.redirect_url, "_blank");
          this.dialogRef.close();
        } else {
          this.printToastMsg(res.message);
        }
        console.log(res, " <<<--------------->>> poshvine login response");
        this.ngxSpinner.hide();
      });
  }

  printToastMsg(msg) {
    this.toast.open(msg, "X", {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "custom-snackbar",
    });
  }
}

// abc
