import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { apis } from 'src/app/config/apis';
import { CrudService } from 'src/app/core/services/crud.service';

@Component({
  selector: 'app-poshvine-login',
  templateUrl: './poshvine-login.component.html',
  styleUrls: ['./poshvine-login.component.scss',
              "../../../../shared/modals/register/register.component.scss"]
})
export class PoshvineLoginComponent implements OnInit {

  selectedCC: any = 95;
  user_details_disable:any = false;
  login: FormGroup;
  showCountryCode: any;
  
  constructor(
    public dialogRef: MatDialogRef<PoshvineLoginComponent>,
    private fb: FormBuilder,
    private crudService: CrudService,
    private ngxSpinner: NgxSpinnerService,
    public dialog: MatDialog,
    public toast:MatSnackBar
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCountryCode();
  }

  createForm(){
    this.login = this.fb.group({
      mobile: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("[0-9]+"),],],
      email: ["", Validators.compose([ Validators.required, Validators.email, Validators.pattern(/\S+@\S+\.\S+/)]),],
      country_code: ["91"],
    });
  }

  getCountryCode() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe((data:any) => {
      this.showCountryCode = data.codes;

    });
  }

  // select country code
  selectedCountryCode(arg) {
    this.login.controls['country_code'].setValue(arg)
  }

  poshvineLogin(){
    this.ngxSpinner.show();
    const reqBody = {
      "mobile" : this.login.get('mobile').value,
      "country_code" : this.login.get('country_code').value,
      "email" : this.login.get('email').value
    }
    this.crudService.postLogin(apis.POSHVINE_LOGIN,reqBody).subscribe((res:any)=>{
      if(res.redirect_url){
        window.open(res.redirect_url, '_blank');
        this.dialogRef.close();
      }
      else{
        this.printToastMsg(res.message);
      }
      console.log(res,' <<<--------------->>> poshvine login response')
      this.ngxSpinner.hide();
    })
  }

  printToastMsg(msg) {
    this.toast.open(msg, "X", {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "custom-snackbar",
    });
  }

}
