import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { apis } from "src/app/config/apis";
import { CrudService } from "src/app/core/services/crud.service";

@Component({
  selector: "app-fimoney-login",
  templateUrl: "./fimoney-login.component.html",
  styleUrls: [
    "./fimoney-login.component.scss",
    "../master-card-login/master-card-login.component.scss",
  ],
})
export class FimoneyLoginComponent implements OnInit {
  selectedCC: any = 95;
  user_details_disable: any = false;
  login: FormGroup;
  registration: FormGroup;
  showCountryCode: any;
  fiMoneyUrl: string;

  constructor(
    public dialogRef: MatDialogRef<FimoneyLoginComponent>,
    private fb: FormBuilder,
    private crudService: CrudService,
    private ngxSpinner: NgxSpinnerService,
    public dialog: MatDialog,
    public toast: MatSnackBar
  ) {}

  ngOnInit() {
    this.createForm();
    this.getCountryCode();
  }

  createForm() {
    this.registration = this.fb.group({
      name: ["", Validators.required],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(/\S+@\S+\.\S+/),
        ]),
      ],
      customer_id: ["", Validators.required],
      country_code: [91],
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("[0-9]+"),
        ],
      ],
      // usages: ["2"],
    });

    this.login = this.fb.group({
      name: ["", Validators.required],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(/\S+@\S+\.\S+/),
        ]),
      ],
      customer_id: ["", Validators.required],
      country_code: ["91"],
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("[0-9]+"),
        ],
      ],
    });
  }

  getCountryCode() {
    this.crudService.get(apis.COUNTRY_CODES).subscribe((data: any) => {
      this.showCountryCode = data.codes;
    });
  }

  // select country code
  selectedCountryCode(arg) {
    this.login.controls["country_code"].setValue(arg);
    this.registration.controls["country_code"].setValue(arg);
  }

  // DropDown Value set function
  // handleDropdownEvent(formControllName, value) {
  //   switch (formControllName) {
  //     case "usages":
  //       this.registration.controls["usages"].setValue(value);

  //       break;
  //   }
  // }

  fiMoneyRegistration() {
    this.ngxSpinner.show();
    const reqBody = {
      customer_name: this.registration.get("name").value,
      customer_email: this.registration.get("email").value,
      customerID: this.registration.get("customer_id").value.toUpperCase(),
      customer_mobile: this.registration.get("mobile").value,
      customer_country_code: this.registration.get("country_code").value,
      // no_of_usage: Number(this.registration.get("usages").value),
    };
    this.crudService
      .postLogin(apis.FI_MONEY_REGISTRATION, reqBody)
      .subscribe((res: any) => {
        if (res.redirect_url) {
          this.openUrlInNewTab(res.redirect_url);
        } else {
          this.printToastMsg(res.message);
          this.ngxSpinner.hide();
        }
        this.ngxSpinner.hide();
      });
  }

  fiMoneyLogin() {
    this.ngxSpinner.show();
    const reqBody = {
      customer_name: this.login.get("name").value,
      customer_email: this.login.get("email").value,
      customerID: this.login.get("customer_id").value.toUpperCase(),
      customer_mobile: this.login.get("mobile").value,
      customer_country_code: this.login.get("country_code").value,
    };
    this.crudService
      .postLogin(apis.FI_MONEY_LOGIN, reqBody)
      .subscribe((res: any) => {
        if (res.redirect_url) {
          this.openUrlInNewTab(res.redirect_url);
        } else {
          this.printToastMsg(res.message);
          this.ngxSpinner.hide();
        }
        this.ngxSpinner.hide();
      });
  }

  openUrlInNewTab(url: string) {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isMobileSafari =
      isIOS &&
      navigator.userAgent.includes("Safari") &&
      !navigator.userAgent.includes("CriOS");
    if (isMobileSafari) {
      this.fiMoneyUrl = url;
    } else {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.rel = "noopener noreferrer"; // Security best practice
      window.document.body.appendChild(a);
      a.click();
      this.dialogRef.close();
    }
  }

  confirmPopUp() {
    window.open(this.fiMoneyUrl, "_blank");
    this.dialogRef.close();
  }

  printToastMsg(msg) {
    this.toast.open(msg, "X", {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "custom-snackbar",
    });
  }
}
